import React from "react";
import { API_URL, postRequest } from "./APIRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

class Login extends React.Component<{ setForceLogin: any }, { username: string, password: string, message: string, success: boolean }> {

    constructor(props: any) {
        super(props);

        this.state = {
            username: "",
            password: "",
            message: "",
            success: false
        }

        this.setUsername = this.setUsername.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.setMessage = this.setMessage.bind(this);
        this.setSuccess = this.setSuccess.bind(this);
        this.login = this.login.bind(this);
        this.pressEnter = this.pressEnter.bind(this);
    }

    setUsername = (e: any) => {
        this.setState({ username: e.target.value });
    }

    setPassword = (e: any) => {
        this.setState({ password: e.target.value });
    }

    setMessage = (e: any) => {
        this.setState({ message: e });
    }

    setSuccess = (e: any) => {
        this.setState({ success: e });
        if (e) {
            setTimeout(() => {
                this.props.setForceLogin(false);
            }, 5000);
        }
    }

    login = () => {
        postRequest(API_URL + "/api/auth/login", {
            username: this.state.username,
            password: this.state.password
        }).then((response) => {
            localStorage.setItem("TOKEN", response.token);
            this.setMessage(response.message);
            this.setSuccess(response.status);
        });
    }

    pressEnter = (e: any) => {
        if (e.key === "Enter") {
            this.login();
        }
    }

    render() {
        return (
            <div className="relative min-h-screen flex">
                <div className="flex-1 bg-gray-800 h-screen text-white p-4">
                    <h1 className="text-4xl text-center my-4">Workplace</h1>

                    <div className="max-w-sm mx-auto">
                        <div className="mb-4">
                            <div className="text-center bg-gray-900 p-2 rounded-lg">Tervetuloa Workplaceen.</div>
                        </div>
                        <div className="mb-4">
                            <input type="text" onKeyDown={this.pressEnter} onChange={this.setUsername} className="text-white bg-gray-800 w-full p-2 rounded-lg shadow-lg border-gray-700 border-2" placeholder="Käyttäjätunnus" />
                        </div>
                        <div className="mb-4">
                            <input type="password" onKeyDown={this.pressEnter} onChange={this.setPassword} className="text-white bg-gray-800 w-full p-2 rounded-lg border-gray-700 border-2" placeholder="Salasana" />
                        </div>
                        <div className="mb-4">
                            <button onClick={this.login} className="bg-indigo-800 w-full p-2 rounded-lg">Kirjaudu sisään</button>
                        </div>
                        <div className={this.state.message ? "mb-4" : "hidden"}>
                            <div className="text-center bg-gray-900 p-2 rounded-lg">{this.state.success ? <FontAwesomeIcon icon={faGear} className="animate-spin" /> : ""} {this.state.message}</div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Login;