import React from "react";
import GitHubCommits from "../util/GitHubCommits";

class Home extends React.Component<{ visible: boolean }> {

    render() {
        if (this.props.visible) {
            return (
                <div>
                    <div className="bg-gray-900 p-4 rounded-lg mb-4">
                        <h1 className="text-2xl">Workplace</h1>
                        <p className="p-2">Workplace on uudistumassa 🚀</p>
                    </div>
                    <GitHubCommits />
                </div>
            );
        }
    }
}

export default Home;