import React from "react";

import { faAdd, faArrowLeftLong, faArrowRight, faBarChart, faCar, faHouse, faLineChart, faPerson, faRemove, faSearch, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SideNav extends React.Component<{ setMenuSelector: any }, { menuSelection: number, hidden: boolean, autoMenu: boolean, analysisMenu: boolean }> {

    constructor(props: any) {
        super(props);

        this.state = {
            menuSelection: 1,
            hidden: false,
            autoMenu: false,
            analysisMenu: false
        }
    }

    setMenuSelection = (e: any) => {
        this.setState({ menuSelection: e });
        this.props.setMenuSelector(e);
    }

    toggleHidden = () => {
        this.setState({ hidden: !this.state.hidden });
    }

    toggleAutoMenu = () => {
        this.setState({ autoMenu: !this.state.autoMenu });
    }

    toggleAnalysisMenu = () => {
        this.setState({ analysisMenu: !this.state.analysisMenu });
    }

    render() {
        return (
            <div>
                <div className={this.state.hidden ? "hidden" : "md:relative w-64 bg-gray-900 h-full fixed z-10"}>
                    <div className="p-4 text-xl text-white bg-gray-900 border-gray-700 border-b"><FontAwesomeIcon icon={faBarChart} /> Workplace <FontAwesomeIcon className="text-red-600 float-right" icon={faSignOut} onClick={() => { localStorage.removeItem('TOKEN'); window.location.reload(); }} /></div>
                    <nav>
                        <a href="#/" onClick={() => this.setMenuSelection(1)} className={this.state.menuSelection === 1 ? "block py-2.5 px-4 text-md text-white bg-indigo-800 hover:bg-indigo-800" : "block py-2.5 px-4 text-md shadow-lg text-white hover:bg-indigo-800"}>
                            <FontAwesomeIcon icon={faHouse} className="pr-4" />Etusivu
                        </a>

                        <a href="#/" onClick={() => this.toggleAutoMenu()} className={this.state.menuSelection === 20 ? "block py-2.5 px-4 text-md text-white bg-indigo-800 hover:bg-indigo-800" : "block py-2.5 px-4 text-md shadow-lg text-white hover:bg-indigo-800"}>
                            <FontAwesomeIcon icon={faCar} className="pr-4" />Autot
                        </a>
                        <div className={this.state.autoMenu ? "" : "hidden"}>
                            <a href="#/" onClick={() => this.setMenuSelection(30)} className={this.state.menuSelection === 30 ? "block py-2.5 px-8 text-xs text-white bg-indigo-800 hover:bg-indigo-800" : "block py-2.5 px-8 text-xs bg-gray-800 text-white hover:bg-indigo-800"}>
                                <FontAwesomeIcon icon={faAdd} className="pr-2" />Lisää Kiveniskutyö
                            </a>
                            <a href="#/" onClick={() => this.setMenuSelection(31)} className={this.state.menuSelection === 31 ? "block py-2.5 px-8 text-xs text-white bg-indigo-800 hover:bg-indigo-800" : "block py-2.5 px-8 text-xs bg-gray-800 text-white hover:bg-indigo-800"}>
                                <FontAwesomeIcon icon={faSearch} className="pr-2" />Hae Kiveniskutöitä
                            </a>
                            <a href="#/" onClick={() => this.setMenuSelection(32)} className={this.state.menuSelection === 32 ? "block py-2.5 px-8 text-xs text-white bg-indigo-800 hover:bg-indigo-800" : "block py-2.5 px-8 text-xs bg-gray-800 text-white hover:bg-indigo-800"}>
                                <FontAwesomeIcon icon={faRemove} className="pr-2" />Poistetut Kiveniskutyöt
                            </a>
                        </div>

                        <a href="#/" onClick={() => this.setMenuSelection(60)} className={this.state.menuSelection === 20 ? "block py-2.5 px-4 text-md text-white bg-indigo-800 hover:bg-indigo-800" : "block py-2.5 px-4 text-md shadow-lg text-white hover:bg-indigo-800"}>
                            <FontAwesomeIcon icon={faPerson} className="pr-4" />Käyttäjät
                        </a>

                        <a href="#/" onClick={() => this.toggleAnalysisMenu()} className={this.state.menuSelection === 20 ? "block py-2.5 px-4 text-md text-white bg-indigo-800 hover:bg-indigo-800" : "block py-2.5 px-4 text-md shadow-lg text-white hover:bg-indigo-800"}>
                            <FontAwesomeIcon icon={faLineChart} className="pr-4" />Analytiikka
                        </a>
                        <div className={this.state.analysisMenu ? "" : "hidden"}>
                            <a href="#/" onClick={() => this.setMenuSelection(40)} className={this.state.menuSelection === 40 ? "block py-2.5 px-8 text-xs text-white bg-indigo-800 hover:bg-indigo-800" : "block py-2.5 px-8 text-xs bg-gray-800 text-white hover:bg-indigo-800"}>
                                <FontAwesomeIcon icon={faCar} className="pr-2" />Kivenisku Analyysi
                            </a>
                        </div>

                        <a href="#/" onClick={() => this.toggleHidden()} className="md:hidden block relative py-2.5 px-4 text-md text-white bg-red-800 hover:bg-red-700 bottom-0 right-0 left-0">
                            <FontAwesomeIcon icon={faArrowLeftLong} className="pr-4" />Piilota Valikko
                        </a>
                    </nav>
                </div>
                <div onClick={() => this.toggleHidden()} className={this.state.hidden ? "fixed rounded-tr-full rounded-br-full bg-slate-700 bottom-1/2" : "hidden"}>
                    <FontAwesomeIcon icon={faArrowRight} className="text-white pl-2 pt-4 pb-4 pr-4" />
                </div>
            </div>
        );
    }
}

export default SideNav;