import React from 'react';
import SideNav from './SideNav';
import Home from './pages/Home';
import Login from './Login';
import StonechipAdd from './pages/StonechipAdd';
import StonechipSearch from './pages/StonechipSearch';
import { API_URL, getRequest } from './APIRequest';
import StonechipDeleted from './pages/StonechipDeleted';
import Users from './pages/Users';
import StonechipAnalysis from './pages/StonechipAnalysis';
class App extends React.Component<{}, { menuSelection: number, forceLogin: boolean }> {

  constructor(props: any) {
    super(props);

    this.state = {
      menuSelection: 1,
      forceLogin: localStorage.getItem("TOKEN") === null ? true : false
    }

    this.setMenuSelector = this.setMenuSelector.bind(this);
    this.setForceLogin = this.setForceLogin.bind(this);
  }

  componentDidMount(): void {
    this.validateToken();

    setInterval(() => {
      this.validateToken();
    }, 60000);
  }

  setMenuSelector = (e: any) => {
    this.setState({ menuSelection: e });
  }

  setForceLogin = (e: any) => {
    this.setState({ forceLogin: e });
  }

  validateToken = () => {
    getRequest(API_URL + "/api/session/validate").then((response) => {
      if (!response.status) {
        localStorage.removeItem("TOKEN");
        this.setState({ forceLogin: true });
      }
    });
  }

  render() {
    if (this.state.forceLogin) {
      return <Login setForceLogin={this.setForceLogin} />
    } else if (!this.state.forceLogin) {
      return (
        <div className="relative min-h-screen flex">
          <SideNav setMenuSelector={this.setMenuSelector} />
          <div className="flex-1 bg-gray-800 min-h-screen h-full text-white p-4">
            <Home visible={this.state.menuSelection === 1} />
            <StonechipAdd visible={this.state.menuSelection === 30} />
            <StonechipSearch visible={this.state.menuSelection === 31} />
            <StonechipDeleted visible={this.state.menuSelection === 32} />
            <StonechipAnalysis visible={this.state.menuSelection === 40} />
            <Users visible={this.state.menuSelection === 60} />
          </div>
        </div>
      );
    }
  }
}

export default App;
