import React from "react";
import { API_URL, getRequest } from "../APIRequest";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

class StonechipOverallData extends React.Component<{}, { series: any, options: any, loading: boolean }> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            series: [{
                data: []
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                }
            }
        }

        this.loadAnalysis = this.loadAnalysis.bind(this);
    }

    componentDidMount(): void {
        this.loadAnalysis();
    }

    loadAnalysis = () => {
        this.setState({ loading: true });
        this.setState({ series: [{ data: [] }] });
        this.setState({ options: { chart: { type: 'bar', height: 350 }, plotOptions: { bar: { borderRadius: 4, horizontal: true, } }, dataLabels: { enabled: false }, xaxis: { categories: [], } } });

        getRequest(API_URL + "/api/rock/analysis/overall").then((response) => {
            // Result: [{ "company": "Company A", "entries": 45 }]

            response.entries.sort((a: any, b: any) => {
                return b.entries - a.entries;
            });

            let seriesData: any = [];
            let categories: any = [];

            response.entries.forEach((item: any) => {
                seriesData.push(item.entries);
                categories.push(item.company);
                this.setState({ loading: false });
            });

            this.setState({
                series: [{
                    data: seriesData,
                    name: "Kiveniskut"
                }],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: categories,
                    },
                    theme: {
                        mode: 'dark',
                        palette: 'palette1',
                        monochrome: {
                            enabled: false,
                            color: '#255aee',
                            shadeTo: 'light',
                            shadeIntensity: 0.65
                        },
                    },
                }
            });
        });
    }

    render() {
        return (
            <div>
                <button onClick={this.loadAnalysis} className="bg-gray-800 text-white p-2 rounded-lg col-span-2 border-2 border-gray-700 mb-2">{this.state.loading ? <FontAwesomeIcon icon={faGear} /> : "Päivitä"}</button>
                <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
            </div>
        )
    }
}

export default StonechipOverallData;