import React from "react";
import { API_URL, getRequest } from "../APIRequest";
import ChangePasswordField from "../util/ChangePasswordField";
import ChangeTitleField from "../util/ChangeTitleField";

class Users extends React.Component<{ visible: boolean }, { users: any }> {

    constructor(props: any) {
        super(props);

        this.state = {
            users: []
        }

        this.getUsers = this.getUsers.bind(this);
    }

    componentDidMount(): void {
        this.getUsers();
    }

    getUsers = () => {
        getRequest(API_URL + "/api/user/list").then((response) => {
            this.setState({ users: response.users ? response.users : [] });
        });
    }

    render() {
        if (this.props.visible) {
            return (
                <div className="bg-gray-900 p-4 rounded-lg mb-4">
                    <h1 className="text-2xl mb-2">Käyttäjät</h1>
                    <p className="text-gray-400 text-sm mb-2">Tämä sivusto näyttää kaikki järjestelmän käyttäjät.</p>
                    {this.state.users ? this.state.users.map((user: any) => {
                        return (
                            <div key={user.id} className="bg-gray-800 p-4 rounded-lg mb-4">
                                <h1 className="text-xl mb-2">{user.username}</h1>
                                <p className="text-gray-400 text-sm mb-2">Käyttäjänimi: {user.username}</p>
                                <p className="text-gray-400 text-sm mb-2">Nimi: {user.firstname} {user.lastname}</p>
                                <p className="text-gray-400 text-sm mb-2"><ChangeTitleField getUsersFunc={this.getUsers} userId={user.id} title={"Titteli: " + user.title} /></p>
                                <div className=""><ChangePasswordField getUsersFunc={this.getUsers} userId={user.id} /></div>
                            </div>
                        );
                    }) : null}
                </div>
            );
        }
    }
}

export default Users;