import React from "react";
import { API_URL, getRequest } from "../APIRequest";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

class StonechipSpecificData extends React.Component<{}, { year: number, company: string, companyList: [], series: any, options: any, loading: boolean }> {

    constructor(props: any) {
        super(props);

        this.state = {
            year: new Date().getFullYear(),
            company: "",
            companyList: [],
            loading: false,
            series: [{
                data: []
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    title: {
                        text: 'Määrät'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        text: 'Määrä'
                    }
                }
            }
        }

        this.loadAnalysis = this.loadAnalysis.bind(this);
    }

    loadCompanies = () => {
        getRequest(API_URL + "/api/customer/list").then((response) => {
            this.setState({ companyList: response.customers ? response.customers : [] });
        });
    }

    componentDidMount(): void {
        this.loadCompanies();
        //this.loadAnalysis();
    }

    loadAnalysis = () => {
        this.setState({ loading: true });
        this.setState({ series: [{ data: [] }] });
        this.setState({ options: { chart: { type: 'bar', height: 350 }, plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' }, }, stroke: { show: true, width: 2, colors: ['transparent'] }, xaxis: { categories: [] }, yaxis: { title: { text: 'Määrät' } }, fill: { opacity: 1 }, tooltip: { y: { text: 'Määrä' } } } });

        getRequest(API_URL + `/api/rock/analysis/specific/${this.state.year}/${this.state.company}`).then((response) => {

            let seriesData: any = [];
            let categories: any = [];

            response.entries.forEach((item: any) => {
                seriesData.push(item.total_entries);
                categories.push(item.month_year);
                this.setState({ loading: false });
            });

            this.setState({
                series: [{
                    data: seriesData,
                    name: "Kiveniskut"
                }],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: categories
                    },
                    yaxis: {
                        title: {
                            text: 'Määrät'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            text: 'Määrä'
                        }
                    },
                    theme: {
                        mode: 'dark',
                        palette: 'palette1',
                        monochrome: {
                            enabled: false,
                            color: '#255aee',
                            shadeTo: 'light',
                            shadeIntensity: 0.65
                        },
                    }
                }
            });
        });
    }

    generateYearOptions() {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let i = currentYear; i >= currentYear - 10; i--) {
            years.push(<option key={i} value={i}>{i}</option>);
        }
        return years;
    }

    render() {
        return (
            <div>
                <button type="button" onClick={this.loadAnalysis} className="bg-gray-800 text-white p-2 rounded-lg col-span-2 border-2 border-gray-700 mb-2">{this.state.loading ? <FontAwesomeIcon icon={faGear} /> : "Päivitä"}</button> 
                &nbsp;
                <select id="yearSelect" className="bg-gray-800 text-white p-2 rounded-lg col-span-2 border-2 border-gray-700 mb-2" value={this.state.year} onChange={(e) => { this.setState({ year: parseInt(e.target.value) }); }}>
                    {this.generateYearOptions()}
                </select>
                &nbsp;
                <select id="companySelect" className="bg-gray-800 text-white p-2 rounded-lg col-span-2 border-2 border-gray-700 mb-2" value={this.state.company} onChange={(e) => { this.setState({ company: e.target.value }); }}>
                    {this.state.companyList.map((item: any) => {
                        return <option key={item.id} value={item.id}>{item.name}</option>
                    })}
                </select>
                <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
            </div>
        )
    }
}

export default StonechipSpecificData;