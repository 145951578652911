import React from "react";
import { API_URL, getRequest, postRequest } from "../APIRequest";
import toast from "react-hot-toast";

class StonechipAdd extends React.Component<{ visible: boolean }, { users: any, customers: any, formData: any, submitDisabled: boolean }> {

    constructor(props: any) {
        super(props);

        this.state = {
            users: [],
            customers: [],
            formData: {
                company: "",
                city: "",
                worker: "",
                date: "",
                car: ""
            },
            submitDisabled: true
        }
    }

    componentDidUpdate(prevProps: Readonly<{ visible: boolean; }>, prevState: Readonly<{ users: any; customers: any; formData: any; }>, snapshot?: any): void {
        if (prevProps.visible !== this.props.visible) {
            getRequest(API_URL + "/api/user/list").then((response) => {
                this.setState({ users: response.users ? response.users : [] });

                setTimeout(() => {
                    this.loadWorkerAsDefault();
                }, 100);
            });

            getRequest(API_URL + "/api/customer/list").then((response) => {
                this.setState({ customers: response.customers ? response.customers : [] });
            });
        }

        // If formData.car updates
        if (prevState.formData.car !== this.state.formData.car) {
            // Submit Enabler
            this.submitDisabled(false);
        }
    }

    addCar = () => {
        postRequest(API_URL + "/api/rock/add", {
            company: this.state.formData.company,
            city: this.state.formData.city,
            worker: this.state.formData.worker,
            date: this.state.formData.date,
            car: this.state.formData.car
        }).then((response) => {
            if (response.status) {
                toast.success(response.message, {
                    duration: 5000,
                    position: 'top-right'
                });
                this.submitDisabled(true);
            } else {
                toast.error(response.message, {
                    duration: 5000,
                    position: 'top-right'
                });
            }
        });
    }

    setCompany = (e: any) => {
        this.setState({ formData: { ...this.state.formData, company: e.target.value } });
    }

    setCity = (e: any) => {
        this.setState({ formData: { ...this.state.formData, city: e.target.value } });
    }

    setWorker = (e: any) => {
        this.setState({ formData: { ...this.state.formData, worker: e.target.value } });
        this.saveWorkerAsDefault(e.target.value);
    }

    setDate = (e: any) => {
        this.setState({ formData: { ...this.state.formData, date: e.target.value } });
    }

    setCar = (e: any) => {
        // Set to Uppercase
        e.target.value = e.target.value.toUpperCase();
        this.setState({ formData: { ...this.state.formData, car: e.target.value } });
    }

    saveWorkerAsDefault = (value: string) => {
        localStorage.setItem("defaultWorker", value);
    }

    loadWorkerAsDefault = () => {
        const defaultWorkerId = localStorage.getItem("defaultWorker");
        this.setWorker({ target: { value: defaultWorkerId } });
        // Update Dropdown value based on "key" attribute of option
        const select = document.getElementById("worker") as HTMLSelectElement;
        if (select) {
            for (let i = 0; i < select.options.length; i++) {
                // If value matches, set as selected
                if (select.options[i].value === defaultWorkerId) {
                    select.options[i].selected = true;
                }
            }
        }
    }

    submitDisabled = (b: boolean) => {
        this.setState({ submitDisabled: b });
    }

    render() {
        if (this.props.visible) {
            return (
                <div>
                    <div className="bg-gray-900 p-4 rounded-lg mb-4">
                        <h1 className="text-2xl">Kiveniskutöiden lisääminen</h1>
                        <p className="p-2">Täytä lomake lisätäksesi kiveniskutyö.</p>
                    </div>
                    <div className="mx-auto bg-gray-900 p-4 rounded-lg">
                        <div className="grid grid-cols-2 pb-1 pl-1">
                            Asiakasyritys
                        </div>
                        <div className="grid grid-cols-2 mb-4">
                            <select onChange={this.setCompany} className="bg-gray-800 text-white p-2 rounded-lg col-span-2">
                                <option value="">Valitse...</option>
                                {this.state.customers.map((customer: any) => {
                                    return (
                                        <option key={customer.id} value={customer.id}>{customer.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="grid grid-cols-2 pb-1 pl-1">
                            Paikkakunta
                        </div>
                        <div className="grid grid-cols-2 mb-4">
                            <input type="text" onChange={this.setCity} className="bg-gray-800 text-white p-2 rounded-lg col-span-2" />
                        </div>
                        <div className="grid grid-cols-2 pb-1 pl-1">
                            Työsuoritus
                        </div>
                        <div className="grid grid-cols-2 mb-4">
                            <select onChange={this.setWorker} id="worker" className="bg-gray-800 text-white p-2 rounded-lg col-span-2">
                                <option value="">Valitse...</option>
                                {this.state.users.map((user: any) => {
                                    return (
                                        <option key={user.id} value={user.id}>{user.nickname}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="grid grid-cols-2 pb-1 pl-1">
                            Päivämäärä
                        </div>
                        <div className="grid grid-cols-2 mb-4">
                            <input type="date" onChange={this.setDate} className="bg-gray-800 text-white p-2 rounded-lg col-span-2" />
                        </div>
                        <div className="grid grid-cols-2 pb-1 pl-1">
                            <div className="col-span-2">Rekisterinumero</div>
                        </div>
                        <div className="grid grid-cols-2 emb-4 gap-2">
                            <input type="text" onChange={this.setCar} className="bg-gray-800 uppercase text-white p-2 rounded-lg col-span-2" />
                        </div>
                        <div className="grid grid-cols-2 mt-8">
                            <input onClick={this.addCar} disabled={this.state.submitDisabled} type="button" value="Lisää" className="bg-green-600 text-white p-2 rounded-lg col-span-2 disabled:bg-gray-500" />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default StonechipAdd;