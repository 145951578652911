import React from "react";
import { API_URL, getRequest } from "../APIRequest";

class GitHubCommits extends React.Component<{}, { payloads: any }> {

    constructor(props: any) {
        super(props);

        this.state = {
            payloads: []
        }

        this.getPayloads = this.getPayloads.bind(this);
    }

    componentDidMount(): void {
        this.getPayloads();
    }

    getDate(date: any) {
        // DD.MM.YYYY HH:MM (Zero Padded)
        let d = new Date(date);
        let day = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        let hours = d.getHours();
        let minutes = d.getMinutes();

        return (day < 10 ? "0" + day : day) + "." + (month < 10 ? "0" + month : month) + "." + year + " " + (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
    }

    getEpoch(date: any) {
        // Timestamp -> DD.MM.YYYY HH:MM (Zero Padded)
        let d = new Date(date * 1000);
        let day = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        let hours = d.getHours();
        let minutes = d.getMinutes();

        return (day < 10 ? "0" + day : day) + "." + (month < 10 ? "0" + month : month) + "." + year + " " + (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
    }

    generateRandomKey() {
        return Math.random().toString(36).substring(7);
    }

    getPayloads = () => {
        getRequest(API_URL + "/api/webhook/github").then((response) => {
            this.setState({ payloads: response.payloads });
        });
    }

    render() {
        return (
            <div className="bg-gray-900 p-4 rounded-lg mb-4">
                <h1 className="text-2xl mb-2">Päivityshistoria</h1>
                <p className="text-gray-400 text-sm mb-2">Tämä sivusto näyttää viimeisimmät 20 muutosta koodiin liittyen. Tähän sisällytetään Shark-Client sekä Shark-Server.</p>
                {this.state.payloads ? this.state.payloads.map((payload: any) => {
                    let commits = payload.commits.map((commit: any) => {
                        return (
                            <div className="mt-2" key={this.generateRandomKey()}>
                                <span className="bg-gray-800 border-2 border-gray-700 text-white p-1 text-xs">{this.getDate(commit.timestamp)}</span><span className="text-xs p-1">{commit.message}</span>
                            </div>
                        );
                    });

                    return (
                        <div className="rounded-lg mb-2 w-full" key={this.generateRandomKey()} >
                            <div><span className={payload.repository.name === "Shark-Client" ? "bg-blue-700 border-2 border-blue-500 text-white px-2 py-1 text-xs w-full" : "bg-red-700 border-2 border-red-500 text-white px-2 py-1 text-xs w-full"}>{payload.repository.name} / {this.getEpoch(payload.repository.pushed_at)} / Julkaistu</span></div>
                            <div>{commits}</div>
                        </div>
                    );
                }) : <div className="text-gray-400">Ei päivityksiä</div>}
            </div >
        );
    }
}

export default GitHubCommits;