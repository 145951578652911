const API_URL = 'https://api.sunshark.org';

function readToken(): String {
    var token = localStorage.getItem('TOKEN');
    if (token === null) {
        token = '';
    }
    return token;
}

async function postRequest(url: string, data: any) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': readToken() as string
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Request failed');
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

async function getRequest(url: string) {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': readToken() as string
            }
        });

        if (!response.ok) {
            throw new Error('Request failed.');
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export { API_URL, postRequest, getRequest };