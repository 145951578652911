import React from "react";
import { API_URL, getRequest } from "../APIRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

class StonechipDeleted extends React.Component<{ visible: boolean }, { entries: any, loading: boolean }> {

    constructor(props: any) {
        super(props);

        this.state = {
            entries: [],
            loading: false
        }

        this.getEntries = this.getEntries.bind(this);
    }

    componentDidMount(): void {
        this.getEntries();
    }

    getEntries = () => {
        this.setState({ loading: true });
        this.setEntries([]);
        getRequest(API_URL + "/api/rock/deleted").then((response) => {
            this.setEntries(response.entries);
            this.setState({ loading: false });
        });
    }

    setEntries = (e: any) => {
        this.setState({ entries: e });
    }

    getDate(date: any) {
        let d = new Date(date);
        return d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();
    }

    render() {
        if (this.props.visible) {
            return (
                <div>
                    <div className="bg-gray-900 p-4 rounded-lg mb-4">
                        <h1 className="text-2xl">Poistetut Kiveniskutyöt</h1>
                        <p className="p-2">Täällä on kaikki "poistetut" eli todellisuudessa deaktivoidut kiveniskutyöt. Kun puhdistuksen yö tapahtuu niin nämä deaktivoidut kiveniskutyöt poistuvat keskuudestamme.</p>
                    </div>
                    <div className="bg-gray-900 p-4 mb-4 rounded-lg overflow-auto">
                        <div className="overflow-auto">
                            <button onClick={this.getEntries} className="bg-gray-800 text-white p-2 rounded-lg col-span-2 border-2 border-gray-700 mb-2">{this.state.loading ? <FontAwesomeIcon icon={faGear} className="animate-spin" /> : "Päivitä"}</button>
                            <table className="w-full border-gray-700 border-2 whitespace-nowrap table-auto">
                                <thead>
                                    <tr className="bg-gray-900 border-b-2 border-gray-700 text-left">
                                        <th className="p-2 w-64">Rekisterinumero</th>
                                        <th>Paikkakunta</th>
                                        <th>Päivämäärä</th>
                                        <th>Asiakasyritys</th>
                                        <th>Työsuoritus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.entries ? this.state.entries.map((entry: any) => {
                                        return (
                                            <tr className="border-b-2 border-gray-700" key={entry.id}>
                                                <td className="py-2 px-4 w-64">{entry.car}</td>
                                                <td>{entry.city}</td>
                                                <td>{this.getDate(entry.date)}</td>
                                                <td>{entry.company}</td>
                                                <td>{entry.worker}</td>
                                            </tr>
                                        );
                                    }) : <tr><td colSpan={5} className="text-center p-4">Ei poistettuja kiveniskutöitä tai sitten deaktivoidut työt on poistettu.</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default StonechipDeleted;