import React from "react";
import { API_URL, getRequest } from "../APIRequest";

class StonechipSearch extends React.Component<{ visible: boolean }, { entries: any }> {

    constructor(props: any) {
        super(props);

        this.state = {
            entries: []
        }

        this.searchEntries = this.searchEntries.bind(this);
    }

    setEntries = (e: any) => {
        this.setState({ entries: e });
    }

    searchEntries(e: any) {
        if (e.target.value === "") {
            this.setEntries([]);
            return;
        }

        e.target.value = e.target.value.toUpperCase();

        getRequest(API_URL + "/api/rock/search/" + e.target.value).then((response) => {
            this.setEntries(response.entries);
        });
    }

    getDate(date: any) {
        let d = new Date(date);
        return d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();
    }

    render() {
        if (this.props.visible) {
            return (
                <div>
                    <div className="bg-gray-900 p-4 rounded-lg mb-4">
                        <h1 className="text-2xl">Kiveniskutöiden haku</h1>
                        <p className="p-2">Täältä voit hakea kiveniskutöitä syöttämällä rekisterinumeron.</p>
                    </div>
                    <div className="bg-gray-900 p-4 mb-4 rounded-lg overflow-auto">
                        <h1 className="text-2xl mb-2">Haku</h1>
                        <div className="mb-4">
                            <input type="text" onChange={this.searchEntries} className="bg-gray-800 text-white p-2 rounded-lg col-span-2 border-2 border-gray-700 uppercase" placeholder="Rekisterinumero" />
                        </div>
                        <div className="overflow-auto">
                            <table className="w-full border-gray-700 border-2 whitespace-nowrap table-auto">
                                <thead>
                                    <tr className="bg-gray-900 border-b-2 border-gray-700 text-left">
                                        <th className="p-2 w-64">Rekisterinumero</th>
                                        <th>Paikkakunta</th>
                                        <th>Päivämäärä</th>
                                        <th>Asiakasyritys</th>
                                        <th>Työsuoritus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.entries ? this.state.entries.map((entry: any) => {
                                        return (
                                            <tr className="border-b-2 border-gray-700" key={entry.id}>
                                                <td className="py-2 px-4 w-64">{entry.car}</td>
                                                <td>{entry.city}</td>
                                                <td>{this.getDate(entry.date)}</td>
                                                <td>{entry.company}</td>
                                                <td>{entry.worker}</td>
                                            </tr>
                                        );
                                    }) : <tr><td colSpan={5} className="text-center p-4">Ei tuloksia</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default StonechipSearch;