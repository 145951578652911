import React from "react";
import StonechipOverallData from "../util/StonechipOverallData";
import StonechipMonthlyData from "../util/StonechipMonthlyData";
import StonechipLifetimeCountCard from "../util/StonechipLifetimeCountCard";
import StonechipSpecificData from "../util/StonechipSpecificData";

class StonechipAnalysis extends React.Component<{ visible: boolean }, { series: any, options: any }> {

    render() {
        if (this.props.visible) {
            return (
                <div>
                    <div className="bg-gray-900 p-4 rounded-lg mb-4">
                        <h1 className="text-2xl">Kiveniskutöiden analyysit</h1>
                        <p className="p-2">Täällä voit analysoida kiveniskutöitä.</p>
                    </div>
                    <div className="bg-gray-900 p-4 mb-4 rounded-lg overflow-auto">
                        <h1 className="text-2xl mb-2">Määrät</h1>
                        <div className="overflow-auto">
                            <StonechipLifetimeCountCard />
                        </div>
                        <h1 className="text-2xl mb-2">Asiakastilasto</h1>
                        <div className="overflow-auto">
                            <StonechipOverallData />
                        </div>

                        <h1 className="text-2xl mb-2">Kuukausitilasto</h1>
                        <div className="overflow-auto">
                            <StonechipMonthlyData />
                        </div>
                        <h1 className="text-2xl mb-2">Kuukausitilasto per Asiakasyritys</h1>
                        <div className="overflow-auto">
                            <StonechipSpecificData />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default StonechipAnalysis;