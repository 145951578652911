import React from "react";
import { API_URL, postRequest } from "../APIRequest";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";

class ChangePasswordField extends React.Component<{ userId: String, getUsersFunc: any }, { newPassword: String, active: boolean }> {

    constructor(props: any) {
        super(props);

        this.state = {
            newPassword: "",
            active: false
        }

        this.setNewPassword = this.setNewPassword.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    setNewPassword = (e: any) => {
        this.setState({ newPassword: e.target.value });
    }

    noAccess = () => {
        toast.custom((t) => (
            <div>
                <video src="/NoAccess.mp4" autoPlay loop className="object-cover rounded-lg" />
                <p className="text-white text-center p-2 rounded-lg bg-gray-800 mt-2 border-2 border-gray-700">Hyvä yritys, mut ei onnistu 😎</p>
            </div>
        ), {
            duration: 8000,
            position: 'top-right'
        });
    }

    changePassword = () => {
        postRequest(API_URL + "/api/user/changePassword", {
            id: this.props.userId,
            password: this.state.newPassword
        }).then((response) => {
            if (response.status) {
                toast.success(response.message, {
                    duration: 5000,
                    position: 'top-right'
                });
                this.setState({ active: false });
                this.props.getUsersFunc();
            } else {
                toast.error(response.message, {
                    duration: 5000,
                    position: 'top-right'
                });
            }
        });
    }

    render() {
        return (
            <div>
                {this.state.active ? <div className="grid grid-cols-5 gap-2"><input type="text" className="text-white bg-gray-800 w-full p-2 rounded-lg border-gray-700 border-2 col-span-3" placeholder="Uusi salasana" onChange={this.setNewPassword} /><button className="bg-green-800 text-white p-2 rounded-lg border-2 border-green-600 col-span-1" onClick={this.changePassword}><FontAwesomeIcon icon={faCheck} /></button><button className="bg-red-800 text-white p-2 rounded-lg border-2 border-red-600 col-span-1" onClick={() => this.setState({ active: false })}><FontAwesomeIcon icon={faClose} /></button></div> : <button className="bg-gray-800 text-white p-2 rounded-lg col-span-2 border-2 border-gray-700" onClick={() => { this.props.userId === '362868ea-55bd-4c77-872f-b0249156956e' ? this.noAccess() : this.setState({ active: true }) }}>Vaihda salasana</button>}
            </div>
        )
    }
}

export default ChangePasswordField;