import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAutomobile, faGear } from "@fortawesome/free-solid-svg-icons";
import { API_URL, getRequest } from "../APIRequest";

class StonechipLifetimeCountCard extends React.Component<{}, { loading: boolean, totalAmount: number }> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            totalAmount: 0
        };
    }

    // Request
    componentDidMount(): void {
        this.setState({ loading: true });
        getRequest(API_URL + "/api/rock/analysis/total").then((response) => {
            this.setState({ totalAmount: response.entries[0].total_entries ? response.entries[0].total_entries : 0 });
            this.setState({ loading: false });
        });
    }

    render() {
        return (
            <div className="card bg-gray-800 w-24 p-2 rounded-md mb-2">
                <p><FontAwesomeIcon icon={faAutomobile} />&nbsp; {this.state.loading ? <FontAwesomeIcon icon={faGear} className="animate-spin" /> : this.state.totalAmount}</p>
            </div>
        );
    }
}

export default StonechipLifetimeCountCard;